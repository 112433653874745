<template>
  <div>
    <ManageForm2 />
  </div>
</template>
<script>
import ManageForm2 from "@/components/ManageForm2/ManageForm2";
export default {
  components: {
    ManageForm2,
  },
  created() { this.year = JSON.parse(
        Decode.decode(localStorage.getItem("yearSelected"))
      );
  },
};
</script>